<template>
    <div class="SendAction">
        <!-- 发送方式选择 -->
        <!-- <van-action-sheet v-model="IsShow" :actions="sendingMode" @select="SelectSendingMode" :close-on-click-overlay="false" /> -->
        <!-- 延迟发送 -->
        <van-action-sheet v-model="delayShow" :actions="delayMode" @select="delayModeSeend" @cancel="cancelChange" />
        <!-- 定时发送 -->
        <van-datetime-picker v-show="timingShow" v-model="currentDate" type="datetime" @confirm="timingModeSeend" @cancel="cancelChange" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isEmptyObject } from '@/libs/utils.js'
export default {
    name: 'SendAction',
    props: {
        originMid: {
            type: String,
            default: function () {
                return ''
            }
        },
        sourceMid: {
            type: String,
            default: function () {
                return ''
            }
        },
        sendType: {
            type: String,
            default: function () {
                return 'new'
            }

        },
        sendingShow: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        ParameterData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        AutomaticallyDelay: {
            type: String,
            default: function () {
                return '0'
            }
        },
        leaveMode: {
            type: String,
            default: function () {
                return 'Seend'
            }
        }
    },
    data() {
        // let _this = this
        return {
            homeTitle: '发送方式选择',
            sendingMode: [{ name: '立即发送', action: 'immediately', buttonIndex: 1 }, { name: '定时发送', action: 'timing', buttonIndex: 2 }, { name: '延时发送', action: 'delayed', buttonIndex: 3 }, { name: '保存草稿', action: 'SaveDraft', buttonIndex: 4 }], // 发送方式
            // ModeShow: ['发送', '立即发送', '定时发送', '延时发送', '保存草稿'],
            delayShow: false, // 延迟显示
            delayMode: [{ name: '10分钟', action: 10 }, { name: '20分钟', action: 20 }, { name: '60分钟', action: 60 }, { name: '120分钟', action: 120 }, { name: '240分钟', action: 240 }, { name: '360分钟', action: 360 }],
            timingShow: false,
            currentDate: new Date(),
            params: {},
            ci: 0,
            leaveType: [{ name: '保存草稿', action: 'SaveDraft', buttonIndex: 0 }, { name: '离开', action: 'leave', buttonIndex: 1 }]

        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {
    },
    mounted() {
    },
    methods: {
        // 发送方式
        SelectSendingMode(item) {
            this.$emit('sendingShowClose')
            if (item.action == 'SendOut') {
                this.$emit('ThroughVerification', 1)
            } else if (item.action == 'immediately') { // 立即发送
                this.$emit('ThroughVerification', 2)
            } else if (item.action == 'timing') { // 定时发送
                this.timingShow = true
                this.$emit('pageShowChange', false)
            } else if (item.action == 'delayed') { // 延时发送
                this.delayShow = true
                // this.sendingMode = this.delayMode
            } else if (item.action == 'SaveDraft') { // 保存草稿
                // this.delayShow = true
                // this.sendingMode = this.delayMode
                this.SaveDraft()
            } else if (item.action == 'leave') { // 离开
                // this.delayShow = true
                // this.sendingMode = this.delayMode
                this.$emit('SaveSource')
                this.$router.go(-1)
            }
        },
        // 根据设定的时间发送
        OrdinarySend() {
            this.params = this.ParameterData
            this.params.action = 'send'
            this.params.type = 0 // 一对多
            this.params.realTime = true
            this.params.sendType = this.sendType
            if (this.sourceMid != '') {
                this.params.sourceMid = this.sourceMid
            }
            if (this.originMid != '' && this.sendType != 'W') {
                this.params.originMid = this.originMid
            }
            this.sendClick(this.params, 1)
        },
        SendImmediately() { // 立即发送
            this.params = this.ParameterData
            this.params.action = 'send'
            this.params.type = 0 // 一对多
            this.params.realTime = true
            this.params.sendType = this.sendType
            if (this.sourceMid != '') {
                this.params.sourceMid = this.sourceMid
            }
            if (this.originMid != '' && this.sendType != 'W') {
                this.params.originMid = this.originMid
            }
            let data = new Date().getTime()
            this.params.deliverDate = this.$m_unifiedTime(data)
            this.sendClick(this.params, 0)
        },
        // 延迟发送
        delayModeSeend(item) {
            this.delayShow = false
            this.params = this.ParameterData
            this.params.realTime = false
            this.params.type = 0 // 一对多
            this.params.sendType = this.sendType
            this.params.action = 'send'
            if (this.sourceMid != '') {
                this.params.sourceMid = this.sourceMid
            }
            if (this.originMid != '' && this.sendType != 'W') {
                this.params.originMid = this.originMid
            }
            let sendTime = new Date().getTime() + item.action * 1000 * 60
            this.params.deliverDate = this.$m_unifiedTime(sendTime)
            let curTime = new Date().getTime()
            if (sendTime <= curTime) {
                // this.$dialog.alert({ message: '发送时间不能小于当前时间！' })
                this.$toast.fail('发送时间不能小于当前时间！')
                return
            }
            this.$emit('ThroughVerification', 3)
        },
        // 定时发送
        timingModeSeend(value) {
            this.params = this.ParameterData
            this.params.type = 0 // 一对多
            this.params.realTime = false
            this.params.sendType = this.sendType
            if (this.sourceMid != '') {
                this.params.sourceMid = this.sourceMid
            }
            if (this.originMid != '' && this.sendType != 'W') {
                this.params.originMid = this.originMid
            }
            this.params.action = 'send'
            let curTime = new Date().getTime()
            this.params.deliverDate = this.$m_unifiedTime(value)
            let sendTime = value.getTime()
            if (sendTime <= curTime) {
                // this.$dialog.alert({ message: '发送时间不能小于当前时间！' })
                this.$toast.fail('发送时间不能小于当前时间！')
                return
            }
            this.timingShow = false
            this.$emit('pageShowChange', true)
            this.$emit('ThroughVerification', 4)
        },
        // 保存草稿箱
        SaveDraft() {
            this.params = this.ParameterData
            this.params.action = 'save'
            this.params.type = 0 // 一对多
            this.params.realTime = true
            this.params.sendType = this.sendType
            if (this.sourceMid != '') {
                this.params.sourceMid = this.sourceMid
            }
            if (this.originMid != '' && this.sendType != 'W') {
                this.params.originMid = this.originMid
            }

            // if (this.types == 0) { // 写
            //     this.ParameterData.sendType = 'new'
            // } else
            // if (this.types == 1) {
            //     this.ParameterData.sendType = 'reply'
            //     this.ParameterData.originMid = this.routeObj.mId
            // } else {
            //     this.ParameterData.sendType = 'repost'
            //     this.ParameterData.originMid = this.routeObj.mId
            // }

            this.sendClick(this.params, 2)
        },
        delayOrTiming() {
            this.sendClick(this.params, 1)
        },
        cancelChange() {
            this.delayShow = false
            this.timingShow = false
            this.$emit('pageShowChange', true)
        },
        async sendClick(params, type) {
            this.$toast.loading({ message: '提交中...' })
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ownerCtId = this.currentCtid
                if (params.sendType == 'reply' || params.sendType == 'repost') {
                    params.sendType = 'rReply'
                }
            } else {
                params.ownerCtId = this.companie.ctId
            }
            // params.sendMailInfo.htmlContent = params.sendMailInfo.htmlContent.replace(/\n/g, '<br>')
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailssavePost
                let res = await this.axios.post(url, params)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.clear()
                    if (type == 0) { // 立即发送
                        let taskId = res.data.data.taskId
                        let mailAccount = params.sendMailInfo.sendUser
                        this.ci = 0
                        this.GetdeliveryStatus(taskId, mailAccount)
                    } else if (type == 1) {
                        this.$emit('SaveSource')
                        this.$toast.success('已提交服务器！')
                        this.$router.go(-1)
                    } else {
                        this.$emit('SaveSource')
                        this.$toast.success('保存草稿成功！')
                        this.$router.go(-1)
                    }
                } else {
                    this.$toast.clear()
                    // this.params.sourceMid = this.taskId // 发送失败要赋值
                    this.$emit('SaveFailed', this.taskId)
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        async GetdeliveryStatus(taskId, mailAccount) {
            this.$toast.loading({ message: '正在发送...', duration: 0 })
            this.ci++
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.GetDeliveryStatus
                let res = await this.axios.get(url, { params: { mailAccount: mailAccount, taskId: taskId } })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let dataObj = res.data.data
                    if ((!dataObj.data || isEmptyObject(dataObj.data)) && this.ci < 20) {
                        setTimeout(() => {
                            this.GetdeliveryStatus(taskId, mailAccount)
                        }, 3 * 1000)
                    } else {
                        if (dataObj.data.singleMailDeliveryStatuses[0].code == '250') {
                            this.$toast.clear()
                            this.$toast.success('发送成功')
                            this.$emit('SaveSource')
                            this.$router.go(-1)
                        } else if (dataObj.data.singleMailDeliveryStatuses[0].code != '250') {
                            this.$toast.clear()
                            let msgArr = dataObj.data.singleMailDeliveryStatuses[0].msg.split(':')
                            // this.$dialog.alert({ title: '提示', message: '发送失败' + msgArr[0] })
                            this.$toast.fail('发送失败' + msgArr[0])
                            // this.params.sourceMid = this.taskId // 发送失败要赋值
                            this.$emit('SaveFailed', this.taskId)
                        }
                    }
                } else {
                    this.$toast.clear()
                    // this.params.sourceMid = this.taskId // 发送失败要赋值
                    this.$emit('SaveFailed', this.taskId)
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        async SendingModeChange(SheetMode) {
            try {
                let res = await this.Global.utils.actionSheet.open(SheetMode)
                if (res.buttonIndex <= SheetMode.length) {
                    for (let index = 0; index < SheetMode.length; index++) {
                        const element = SheetMode[index]
                        if (element.buttonIndex == res.buttonIndex) {
                            this.SelectSendingMode(SheetMode[index])
                            break
                        }
                    }
                }
            } catch (error) {
                this.$emit('sendingShowClose')
            }
        }

    },
    watch: {
        sendingShow: {
            handler(N, O) {
                if (N) {
                    if (this.leaveMode == 'leave') {
                        this.SendingModeChange(this.leaveType)
                    } else {
                        if (this.AutomaticallyDelay == '1') {
                            if (this.sendingMode[0].action != 'SendOut') {
                                this.sendingMode.splice(0, 0, { name: '发送(自动延时)', action: 'SendOut', buttonIndex: 0 })
                            }
                        } else if (this.sendingMode[0].action == 'SendOut') {
                            this.sendingMode.splice(0, 1)
                        }
                        this.SendingModeChange(this.sendingMode)
                    }
                }
            }
        }
    }
}
</script>
