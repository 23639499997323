<template>
    <div class="ChooseFile">
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ChooseFile',
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        /**
         * 上传文件钉盘
         * 1.获取自定义空间 space_id
         * 2.授权上传
         * 3.选文件
         * 4.授权下载
         * 5.获取code ：获取download_key需要，一个code只能用一次
         * 6.获取download_key  api:https://oapi.dingtalk.com/cspace/get_download_key?access_token=${accessToken}&agent_id=${agentId}&dentry_id=${item.fileId}&code=${code}&space_id=${item.spaceId}
         *  7.下根据download_key载文件 api: https://oapi.dingtalk.com/file/download_by_key?access_token=${token}&agent_id=${agentId}&download_key=${keyString}
         */
        async open(isStore = false, uploadData = {}, isMail = false) {
            let returnData = { flag: false }
            try {
                await this.Global.ding.ready()
                let resData = await Promise.all([
                    this.getCustomSpace(),
                    this.grantCustomSpace({ type: 'add' })
                ])

                if (!resData[0].flag) {
                    throw resData[0].msg
                }

                if (!resData[1].flag) {
                    throw resData[1].msg
                }

                let filesData = await this.chooseFile(resData[0].spaceid)
                this.Global.ding.showLoading('上传中...')
                let fileids = filesData.data.map(item => item.fileId).join(',')
                let res01 = await this.grantCustomSpace({ type: 'download', fileids })

                if (!res01.flag) {
                    throw res01.msg
                }

                /*  for (let index = 0; index < filesData.data.length; index++) {
                     let item = filesData.data[index]
                     let { code } = await this.Global.ding.requestAuthCode()
                     item.code = code
                 } */
                let promises = filesData.data.map(item => (async (item) => {
                    let { code } = await this.Global.ding.requestAuthCode()
                    item.code = code
                })(item))

                await Promise.all(promises)

                let { accessToken, agentId } = window.dingdingConfig
                Object.assign(filesData, { ding_token: accessToken, agentId, isStore: isStore ? 1 : 0, targetFolderId: 0 }, uploadData)

                returnData = await this.postData(filesData)
                if (isMail && returnData.flag) {
                    let fileInfos = returnData.data.map(item => {
                        return { name: item.fileName, url: item.dingFileUrl, size: item.fileSize }
                    })
                    returnData = await this.fileTransfer(fileInfos)
                }
            } catch (error) {
                alert(error)
            }
            this.Global.ding.hideLoading()
            return returnData
        },
        async fileTransfer(fileInfos) {
            let returnData = {
                flag: false
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_fileTransfer
                let res = await this.axios.post(url, { fileInfos })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnData.flag = true
                    returnData.data = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                alert(error)
            }
            return returnData
        },
        async  postData(data) {
            let returnData = {
                flag: false
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_dingPan
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnData.flag = true
                    returnData.data = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                alert(error)
            }
            return returnData
        },
        chooseFile(spaceid) {
            return new Promise((resolve, reject) => {
                dd.biz.util.uploadAttachment({
                    image: { multiple: true, compress: true, max: 1, spaceId: spaceid },
                    space: { corpId: window.dingdingConfig.corpId, spaceId: spaceid, isCopy: 1, max: 1 },
                    file: { spaceId: spaceid, max: 1 },
                    types: ['photo', 'camera', 'file', 'space'],
                    onSuccess: function (result) {
                        resolve(result)
                    },
                    onFail: function (err) {
                        reject(JSON.stringify(err))
                    }
                })
            })
        },
        async getCustomSpace() {
            let data = { flag: false }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.dingding.getSpace
                let params = {
                    access_token: window.dingdingConfig.accessToken,
                    agent_id: window.dingdingConfig.agentId
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.data.errcode.toString() !== '0') {
                        data.msg = res.data.data.errmsg
                    } else {
                        data.flag = true
                        data.spaceid = res.data.data.spaceid
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                alert(error)
            }
            return data
        },
        async grantCustomSpace({ type, path = '/', fileids }) {
            let data = { flag: false }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.dingding.grantSpace
                let params = {
                    access_token: window.dingdingConfig.accessToken,
                    agent_id: window.dingdingConfig.agentId,
                    userid: this.userInfo.userid,
                    type: type
                }
                if (type == 'add') {
                    Object.assign(params, { path })
                } else {
                    Object.assign(params, { fileids })
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code === this.Global.config.RES_OK) {
                    if (res.data.data.errcode.toString() !== '0') {
                        data.msg = res.data.data.errmsg
                    } else {
                        data.flag = true
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                alert(error)
            }
            return data
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
</style>
