<template>
    <div class="InputAuto">
        <div class="showItem" v-if="currentArryInput.length>0">
            <span :class="activeIndex==index?'showAddress showAddressActiveColor':'showAddress showAddressColor'" v-for="(item,index) in currentArryInput" :key="index" @click="activeChange(index)">
                <span class="namesubdtring">{{item.name||item.contName||item.ownerName}}</span>
                <span class="spanBox">
                    <span :class="!item.category||item.category==4?'circular circularblank':'circular circularColor'">
                        <!-- <em>{{item.custId!=undefined?'客':'陌'}}</em> -->
                        <em v-if="!item.category||item.category==4">陌</em>
                        <em v-if="item.category==1">客</em>
                        <em v-if="item.category==2">供</em>
                        <em v-if="item.category==3">内</em>

                    </span>
                </span>
            </span>
        </div>
        <input ref="currentInput" v-model="currentInput" @keyup.8="RemoveAddress()" @input="seachInfo()" @keyup.13="changeBlur(currentInput,true)" @focus="$emit('autoReturn')" />
    </div>
</template>
<script>
export default {
    name: 'InputAuto',
    props: {
        currentArry: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: function () {
                return ''
            }

        }
    },
    data() {
        return {
            homeTitle: '输入',
            currentInput: '',
            currentArryInput: [],
            restaurants: [],
            associationShow: false,
            ArryInput: [],
            activeIndex: -1

        }
    },
    computed: {

    },
    created() {
    },
    mounted() {

    },
    methods: {
        autoReturn() {
            if (this.currentInput != '') {
                this.changeBlur(this.currentInput, false)
            }
        },
        activeChange(index) {
            this.activeIndex = index
            this.$refs['currentInput'].focus()
        },
        // 获取当前数据
        getArry() {
            return this.currentArryInput
        },
        getMailArry(inputArry) {
            let InputAddress = []
            for (let index = 0; index < inputArry.length; index++) {
                const element = inputArry[index]
                if (this.checkEmail(element)) {
                    InputAddress.push(element)
                }
            }
            return InputAddress
        },
        // 是否为邮件
        checkEmail(str) {
            var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
            if (re.test(str)) {
                return true
            } else {
                return false
            }
        },
        // 联想搜索
        async seachInfo() {
            this.activeIndex = -1
            if (this.currentInput == '' || this.currentInput.length < 2) {
                this.restaurants = []
                this.associationShow = false
                this.$emit('getStyle', this.type, this.restaurants, this.associationShow)
                return
            }
            if (/@[\w\d]{1,}/.test(this.currentInput)) {
                this.restaurants = await this.getRestaurants(this.restaurants, this.currentInput)
                this.associationShow = this.restaurants.length > 0
                this.$emit('getStyle', this.type, this.restaurants, this.associationShow)
                return
            }
            try {
                const mailsRecUrl = this.Global.config.apiBaseURL + this.Global.api.Mail.mailsrecipientsGet
                const intContactsUrl = this.Global.config.apiBaseURL + this.Global.api.v2.internal_contact_query
                const [mailsRecipientsRes, intContactsRes] = await Promise.all([this.axios.get(mailsRecUrl, { params: { keywords: this.currentInput } }), this.axios.get(intContactsUrl, { params: { keywords: this.currentInput } })])
                if (mailsRecipientsRes.data.code.toString() == this.Global.config.RES_OK) {
                    this.associationShow = true
                    let list = mailsRecipientsRes.data.data.list
                    let listArry = []
                    for (let index = 0; index < list.length; index++) {
                        const element = list[index]
                        listArry.push({ name: element.name,
                            company: element.company,
                            custId: element.custId,
                            mail: element.mailAddress.replace(/<[^>]+>/g, '')
                        })
                    }
                    this.restaurants = listArry
                    this.$emit('getStyle', this.type, this.restaurants, this.associationShow)
                } else {
                    this.$toast.fail(res.data.msg)
                }

                console.log(intContactsRes)

                if (intContactsRes.data.code.toString() == '0') {
                    this.associationShow = true
                    let list = intContactsRes.data.data.dataList
                    for (let index = 0; index < list.length; index++) {
                        const element = list[index]
                        this.restaurants.push({ name: element.realName,
                            mail: element.email.replace(/<[^>]+>/g, '')
                        })
                    }
                    console.log(this.restaurants)
                    this.$emit('getStyle', this.type, this.restaurants, this.associationShow)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        getRestaurants(combinationArray, val) {
            let arry = []
            combinationArray.forEach(element => {
                let mailAddress = element.mail.replace(/<[^>]+>/g, '')
                if (mailAddress.includes(val)) {
                    arry.push(element)
                }
            })
            return arry
        },
        // 联想选择
        SelectRestaurants(item) {
            this.changeBlur(item.mail, true)
            // this.associationShow = false
            // this.$refs['currentInput'].focus()
        },
        // 输入完成
        changeBlur(inputValue, blg) {
            if (inputValue == '') {
                return
            }
            let inputArry = inputValue.split(';')
            let InputAddress = this.getMailArry(inputArry)
            if (InputAddress.length > 1) {
                this.mailToGetCustomerMore(InputAddress)// 更新客陌，【批量】
            } else if (InputAddress.length == 1) {
                this.mailToGetCustomerSingle(InputAddress)// 更新客陌，单个
            }

            this.currentInput = ''
            this.associationShow = false
            if (blg) {
                this.$refs['currentInput'].focus()
            }
            this.$emit('getStyle', this.type, this.restaurants, this.associationShow)
        },
        // 更新客陌，单个
        async  mailToGetCustomerSingle(inputAddressArry) {
            let mailAddress = inputAddressArry[0]
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.getMailsContacts
                let res = await this.axios.get(url, { params: { mailAddress: mailAddress } })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (this.Global.utils.isObject(res.data.data) && res.data.data.category) {
                        this.currentArryInput.push(res.data.data)
                    } else {
                        this.currentArryInput.push({
                            name: mailAddress.split('@')[0],
                            mailAddress: mailAddress
                        })
                    }
                    this.clearRepeat(this.currentArryInput) // 去重
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 更新客陌，【批量】
        async mailToGetCustomerMore(inputAddressArry) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.getMailsContacts
                let res = await this.axios.get(url, { params: { mailAddress: inputAddressArry.join(';') } })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isArray(res.data.data)) {
                    let backArr = res.data.data
                    let currentArry = Object.assign([], this.currentArry)
                    backArr.forEach(item => {
                        currentArry.forEach(item2 => {
                            let str = item2.mail || item2.mailAddress
                            if (item.mailAddress == str) {
                                item2.category = item.category
                                switch (item.category) {
                                    case 1: // 客户联系人
                                        item2.name = item.contName
                                        item2.custId = item.custId
                                        item2.company = item.company
                                        break

                                    case 2:// 供应商：2

                                        break
                                    case 3:// 内部联系人：3
                                        item2.ownerName = item.ownerName
                                        break
                                    case 4:

                                        break
                                }
                            }
                        })
                    })
                    this.currentArryInput = currentArry
                    this.clearRepeat(this.currentArryInput) // 去重
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 去重
        clearRepeat(currentArryInput) {
            let allArr = currentArryInput
            let obj = {}
            allArr = allArr.reduce((cur, next) => {
                if (!obj[next.mailAddress]) {
                    obj[next.mailAddress] = true && cur.push(next)
                }
                return cur
            }, []) // 设置cur默认类型为数组，并且初始值为空的数组
            this.currentArryInput = allArr
        },
        RemoveAddress() {
            if (this.currentInput != '') { // 文字输入删除
                return
            }
            if (this.activeIndex == -1) { // 没有任何选中
                this.activeIndex = this.currentArryInput.length - 1
            } else {
                this.currentArryInput.splice(this.activeIndex, 1)
                this.activeIndex = -1
            }
        }

    },
    watch: {
        currentArry: {
            handler(curVal, oldvalue) {
                if (curVal.length > 1) {
                    let mailAddressArry = []
                    curVal.forEach(element => {
                        mailAddressArry.push(element.mailAddress)
                    })
                    this.mailToGetCustomerMore(mailAddressArry)
                } else if (curVal.length == 1) {
                    this.mailToGetCustomerSingle([curVal[0].mailAddress])
                } else {
                    this.currentArryInput = []
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
.van-actionsheet {
    //  border: 1px red solid;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: #333;
    max-height: 40%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f8f8f8;
}
@import "./en.less";
</style>
