<template>
    <div v-show="isShow" class="SelectFile">
        <van-tabs @click="changeType" v-model="tabIndex" class="tabBox" ref="tabs">
            <van-tab v-for="(tab,index) in tabs" :title="tab" :key="index">
            </van-tab>
        </van-tabs>
        <div class="resultBox van-hairline--top">
            <transition name="van-fade">
                <scroll v-if="!isLoading&&list.length>0" class="wrapper" ref="scroll" :pullUpLoad="pullUpLoadObj" @pullingUp="onPullingUp">
                    <ul class="docList">
                        <van-checkbox-group v-model="checkedItems">
                            <li v-for="(item,index) in list" class="docItem " :class="{'van-hairline--bottom':index!=list.length-1}" @click="item.isFolder?intoFolder(item.folderId,item.folderName):''" :key="index">
                                <template v-if="item.isFolder">
                                    <div class="iconBox">
                                        <i class="iconFONT iconfont " :class="item.folderType==1?'icon-folder-solid':'icon-space-close'"></i>
                                    </div>
                                    <div class="contentBox">
                                        <p class="fileName ellipsis" v-html="item.folderName"></p>
                                        <p class="fileInfo ellipsis">
                                            {{item.modifyDate||item.createDate}}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="iconBox">
                                        <img v-if="isImage(item)" class="iconIMG" :src="item.preViewImageUrl" alt="">
                                        <svg v-else class="iconSVG" aria-hidden="true">
                                            <use :xlink:href="getIcon(item.fileExtName)"></use>
                                        </svg>
                                    </div>
                                    <div class="contentBox">
                                        <p class="fileName ellipsis" v-html="`${item.fileName}.${item.fileExtName}`"></p>
                                        <p class="fileInfo ellipsis">
                                            {{Global.utils.byteCalc(item.fileSize)}}&nbsp;{{item.createRealName}}&nbsp;{{item.modifyDate||item.createDate}}
                                        </p>
                                    </div>
                                    <div class="checkBox">
                                        <van-checkbox :name="item.fileId+'&&&&'+item.fileVersion">
                                        </van-checkbox>
                                    </div>
                                </template>
                            </li>
                        </van-checkbox-group>
                    </ul>
                </scroll>
            </transition>
            <transition name="van-fade">
                <nodata v-if="this.list.length<=0&&!isLoading">
                </nodata>
            </transition>
            <transition name="van-fade">
                <loading v-if="isLoading" size="30px"></loading>
            </transition>
        </div>
        <div class="btmBox van-hairline--top">
            <div @click="goBack" class="goBackBtn ellipsis" :class="{' notOperable':folderNav.length==1}">
                返回上一级
            </div>
            <div @click="cannotCommit?'':commit()" class="saveBtn ellipsis " :class="{'notOperable':cannotCommit}">
                选取 {{checkedItems.length>0?`(${checkedItems.length})`:''}}
            </div>
        </div>
    </div>
</template>

<script>
import Scroll from '@/components/Scroll/index'
export default {
    name: 'SelectFile',
    data() {
        return {
            isShow: false,
            isLoading: false,
            isSaving: false,
            tabs: Object.freeze(['我的文档', '知识库']),
            moduleCode: Object.freeze(['DC002', 'DC001']),
            folderNav: [],
            folderSource: Object.freeze([2, 1]),
            tabIndex: 0,
            page: {
                pageN: 0,
                pageSize: 10,
                total: 0
            },
            list: [],
            pullUpLoad: true,
            pullUpLoadThreshold: 20,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            checkedItems: [],
            cancelPreReq: null
        }
    },
    computed: {
        pullUpLoadObj() {
            return this.pullUpLoad
                ? {
                    threshold: parseInt(this.pullUpLoadThreshold),
                    txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
                }
                : false
        },
        isKRoot() {
            return this.folderNav.length == 1 && this.tabIndex == 1
        },
        cannotCommit() {
            return this.isSaving || this.checkedItems.length <= 0
        }
    },
    methods: {
        show() {
            this.isShow = true
            this.initFolderNav()
            this.reGetList()
            // * ⬇️ 因为用了v-show导致Vant无法计算页面元素的宽度，因此要手动resize()
            this.$refs.tabs.resize()
        },
        close() {
            this.isShow = false
        },

        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        isImage(data) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            return imgArr.includes(data.fileExtName.toLowerCase())
        },

        initFolderNav() {
            let folders = [[{ id: 2, name: '我的文档' }], [{ id: 1, name: '知识库' }]]
            this.folderNav = Object.assign([], folders[this.tabIndex])
            this.checkedItems = []
        },
        goBack() {
            if (this.folderNav.length == 1) {
                return false
            }
            this.folderNav.splice(this.folderNav.length - 1, 1)
            this.reGetList()
        },
        changeType() {
            this.initFolderNav()
            this.reGetList()
        },
        intoFolder(id, name) {
            this.folderNav.push({ id, name })
            this.reGetList()
        },
        async commit() {
            if (this.checkedItems.length <= 0) {
                return
            }

            let tempCheckedDocs = this.checkedItems.reduce((arr, item) => {
                let tempArr = item.split('&&&&')
                arr.push({ fileId: tempArr[0], fileVersion: tempArr[1] })
                return arr
            }, [])

            this.checkedItems = []

            this.$emit('checkedDoc', tempCheckedDocs)
        },

        async reGetList() {
            this.page.pageN = 1
            this.page.total = 0
            this.isLoading = true
            let list = await this.getData()
            if (list) {
                this.list = list
                this.isLoading = false
            }
        },
        async getData() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_files
                let data = {
                    params: {
                        folderSource: this.folderSource[this.tabIndex],
                        folderId: this.folderNav[this.folderNav.length - 1].id,
                        docType: 'ALL',
                        pageN: this.page.pageN,
                        pageSize: this.page.pageSize,
                        moduleCode: this.moduleCode[this.tabIndex],
                        params: 'w_80,h_80,m_fill',
                        optCode: 'otUpload'
                    },
                    cancelToken: new this.axios.CancelToken((cancel) => {
                        if (this.cancelPreReq) {
                            this.cancelPreReq()
                        }
                        this.cancelPreReq = cancel
                    })
                }
                let list = []
                let res = await this.axios.get(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.page.total = res.data.data.totalNum
                    list = res.data.data.list
                } else {
                    this.$toast.falil(res.data.msg)
                }
                return list
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log('is cancel req')
                    return false
                } else {
                    console.log(error)
                    return []
                }
            }
        },
        async onPullingUp() {
            if (this.list.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.list.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.list = this.list.concat(list)
                this.$nextTick(() => {
                    if (this.list.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        }
    },
    components: {
        'scroll': Scroll
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
