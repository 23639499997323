<template>
    <div class="NewMail" id="scrollTopList">
        <div class="inputClass" v-show="pageShow">
            <div class="inputBox">
                <div class="inputArea">
                    <span class="title">发件人：</span>
                    <div class="contenClass" style="margin-right: 20%;" @click="fromExShowChange()">
                        <span class="spanText"> {{fromEx}} </span>
                        <span class="spanright" v-if="fromExArry.length!=1">
                            <i class="iconfont icon-one-to-one"></i>
                        </span>
                    </div>
                    <span v-show="!ccShow" @click="ccAndDarkShow()" :class="[ccShow ? 'togetedown' : 'togeteright']">
                        抄送/密送
                    </span>

                </div>
                <div class="inputArea" id="recipientsId">
                    <span class="title">收件人：</span>
                    <div class="contenClass">
                        <input-auto ref="recipientsPicking_value" :currentArry="recipientsArry" :type="'recipientsId'" @getStyle="getStyle" @autoReturn="autoReturn"></input-auto>
                    </div>
                    <span class="togetedown" @click="selectCustomer('recipientsPicking_value')">
                        <i class="iconfont icon-add-round"></i>
                    </span>

                </div>
                <div class="inputArea" v-show="ccShow" id="ccId">
                    <span class="title" @click="getStyle()">抄送：</span>
                    <div class="contenClass">
                        <input-auto ref="ccPicking_value" :currentArry="ccArry" :type="'ccId'" @getStyle="getStyle" @autoReturn="autoReturn"></input-auto>
                    </div>
                    <span class="togetedown" @click="selectCustomer('ccPicking_value')">
                        <i class="iconfont icon-add-round"></i>
                    </span>
                </div>
                <div class="inputArea" v-show="DarkShow" id="DarkId">

                    <span class="title">暗送：</span>
                    <div class="contenClass">
                        <input-auto ref="DrakPicking_value" :currentArry="DarkArry" :type="'DarkId'" @getStyle="getStyle" @autoReturn="autoReturn"> </input-auto>
                    </div>
                    <span class="togetedown" @click="selectCustomer('DrakPicking_value')">
                        <i class="iconfont icon-add-round"></i>
                    </span>

                </div>
                <div class="inputArea">
                    <span class="title">主题：</span>
                    <div class="contenClass">
                        <!-- placeholder="必填" -->
                        <input v-model="subject" @focus="autoReturn()" />
                    </div>

                </div>
                <div v-if="divShow&&restaurants.length>0" class="associationShowClass" :style="{height:heigthS+'px',top:topS+'px'}">
                    <ul>
                        <li v-for="(item,index) in restaurants" :key="index" @click="SelectRestaurants(item)">
                            <dt>{{item.name}}</dt>
                            <dd>{{item.mail}}</dd>
                        </li>
                    </ul>
                </div>
                <div class="filelistshow" v-if="attachmentList.length>0">
                    <file-list-show ref="file-list-show" :attachmentList="attachmentList" @delAttachmentList="delAttachmentList" :close="close"></file-list-show>
                </div>
                <!-- style="outline: 0; -webkit-user-modify: read-write-plaintext-only;background: pink;"  focusStaus-->
                <div class="contenDivParent" @focus="focusStaus" :style="{height:contenDivheigth+'px'}" id="contenDivParentId">
                    <!-- contenteditable="auto" -->
                    <div class="contenDiv" contenteditable="auto" id="htmlContentId" @focus="contenDivParentFocus">
                        <div v-if="htmlContent!=''" v-html="htmlContent"></div>
                    </div>
                </div>
            </div>
            <div class="buttonArea">
                <span @click="uploadDingFile()">
                    <i class="iconfont icon-pic"></i>
                    <!-- <input type="file" @change="uploadDingFile()" accept="image/*" multiple> -->
                </span>

                <span @click="openSelectDocFile()">
                    <i class="iconfont icon-attachment"></i>
                    <!-- <input type="file" @change="uploadFile($event)" multiple> -->
                </span>
                <span @click="changeEye" :style="{float: 'right',color:enableTrack?'#D0021B':''}"><i class="iconfont icon-kehu-zhuangtai-chakan"></i></span>
            </div>
        </div>
        <!-- 发送方式选择 -->
        <send-action ref="SendAction" :sendingShow="sendingShow" :ParameterData="ParameterData" :leaveMode="leaveMode" :sendType="sendType" :originMid="originMid" :sourceMid="sourceMid" @pageShowChange="pageShowChange" @ThroughVerification="ThroughVerification" @sendingShowClose="sendingShowClose" @SaveFailed="SaveFailed" @SaveSource="Routine=true" :AutomaticallyDelay="AutomaticallyDelay"></send-action>
        <upload-file ref="fileUpload"></upload-file>
        <choose-file ref="ChooseFile"></choose-file>
        <select-doc v-if="isShowSelectDoc" ref="selectDoc" @checkedDoc="checkedDoc"></select-doc>
    </div>
</template>
<script>
import InputAuto from './InputAuto/index'
import SendAction from '../../Mail/MailPublic/SendAction/index'
import UploadFile from '@/components/UploadFiles/index'
import fileListShow from '../MailPublic/fileListShow/index'
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
import ChooseFile from '@/components/ChooseFile/index'
import SelectDoc from '../Vue/SelectDoc'
import { urlIsEncode } from '@/libs/utils.js'
import hybrid from '@/libs/hybrid'

export default {
    name: 'NewMail',
    title: '写邮件',
    mixins: [titleMixin],
    data() {
        var myDate = new Date()
        return {
            actionType: '', // 页面类型   写邮件|回复邮件
            templateid: 0, // 模板Id
            htmlContent: '',
            ccShow: false, // 抄送显示
            DarkShow: false, // 暗送显示
            // fromExShow: false, // 发件人显示
            fromExArry: [], // 发件人
            // fromExArryShow: [], // 发件人
            ccArry: [],
            recipientsArry: [],
            DarkArry: [],
            subject: '',
            fromEx: '',
            fromExCheck: 0,
            sendingShow: false,
            attachmentList: [], // 附件
            recipients: '',
            cc: '',
            bcc: '',
            SettingInformationData: {}, // 设置项
            OriginalMailContent: '', // 原邮件内容
            templateHtmlContent: '', // 模板内容
            sizeAttachMx: 0, // 最大附件大小
            prohibitIput: false, // 禁止输入
            otBeforeLable: false, // 发送前给邮件打标签
            ccArryList: [],
            toArry: [],
            toCCArry: [],
            bccArry: [],
            ParameterData: {},
            pageShow: true,
            sendType: '',
            originMid: '', // 原来邮件id
            sourceMid: '', // 自己的mId
            heigthS: 0,
            topS: 0,
            divShow: false,
            restaurants: [],
            restaurantsType: '',
            close: 1,
            month: myDate.getMonth() + 1,
            time: myDate.getDate(),
            su: 0,
            contenDivheigth: 0,
            beforeType: '',
            originMsgId: '',
            isShowSelectDoc: false,
            focusStaus: false,
            AutomaticallyDelay: '0',
            Routine: false,
            leaveMode: 'Seend',
            enableTrack: false,
            isShowSelectContact: ''

            // showPrompt: 0, // 1提示有给未建档案客户发邮件

        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {
        this.pageUrl = this.$route.path
        const _self = this
        hybrid.on('sendFile', res => {
            console.log('sendfile', res)
            if (res.hasOwnProperty('url')) {
                _self.pushAttachment(res)
            } else {
                console.log('文件错误！')
            }
        })
    },
    mounted() {
        this.pageInit()
        if (this.$route.query.hasOwnProperty('url')) this.pushAttachment(this.$route.query)
    },
    methods: {
        selectCustomer(type) {
            this.autoReturn()
            this.isShowSelectContact = type
            this.$router.push({ name: 'selectCustomer' })
        },
        contenDivParentFocus() {
            // this.focusStaus = true
            this.autoReturn()
        },
        changeEye() {
            this.enableTrack = this.enableTrack != true
            this.$toast(this.enableTrack ? '千里眼已开启' : '千里眼已关闭')
        },
        autoReturn() {
            this.$refs.recipientsPicking_value.autoReturn()
            this.$refs.ccPicking_value.autoReturn()
            this.$refs.DrakPicking_value.autoReturn()
        },
        ccAndDarkShow() {
            this.ccShow = this.ccShow != true
            this.DarkShow = this.DarkShow != true
        },
        loadedContent(content) {
            try {
                const vm = this.$refs.iframe.contentWindow.vm
                vm.showContent(content)
            } catch (err) {
                console.log(' vm.showContent(_this.content) ')
            }
        },
        async fromExShowChange() {
            this.autoReturn()
            try {
                let res = await this.Global.utils.actionSheet.open(this.fromExArry)
                if (res.buttonIndex < this.fromExArry.length) {
                    this.SelectfromEx(this.fromExArry[res.buttonIndex])
                }
            } catch (error) {
                console.log(error)
            }
            if (this.$refs.ccPicking_value.getArry().length == 0 && this.$refs.DrakPicking_value.getArry().length == 0) {
                this.ccShow = false
                this.DarkShow = false
            }
        },
        cleanCoten() {
            this.ccArry = []
            this.recipientsArry = []
            this.DarkArry = []
            this.attachmentList = []
            this.subject = ''
            this.htmlContent = ''
        },
        keepLastIndex(obj) {
            if (window.getSelection) { // ie11 10 9 ff safari
                obj.focus() // 解决ff不获取焦点无法定位问题
                let range = window.getSelection() // 创建range
                range.selectAllChildren(obj) // range 选择obj下所有子内容
                range.collapseToEnd() // 光标移至最后
            } else if (document.selection) { // ie10 9 8 7 6 5
                let range = document.selection.createRange() // 创建选择对象
                range.moveToElementText(obj) // range定位到obj
                range.collapse(false) // 光标移至最后
                range.select()
            }
        },
        delAttachmentList(index) {
            this.attachmentList.splice(index, 1)
        },
        openSelectDocFile() {
            this.isShowSelectDoc = true
            this.Global.utils.rightMenu.clearMenu()
            this.setHeaderTitle('选择文件')
            this.$nextTick(() => {
                this.$refs.selectDoc.show()
            })
        },
        closeSelectDocFile() {
            this.$refs.selectDoc.close()
            this.setMenu()
            this.setHeaderTitle(this.$options.title)
            this.$nextTick(() => {
                this.isShowSelectDoc = false
            })
        },
        checkedDoc(docArr) {
            this.folders_moveFiles('checkedDoc', docArr)
            this.closeSelectDocFile()
        },
        pushAttachment({name, size, url}) {
            this.attachmentList.push({name, size, url})
            if (this.subject == '') {
                this.subject = this.attachmentList[0].name.split('.')[0]
            }
        },
        uploadDingFile() {
            let fileUploadUtils = this.$refs.fileUpload.init()
            fileUploadUtils.on('load', (res) => {
                this.attachmentList.push({ name: res.name, size: res.size, url: this.Global.config.downloadBaseUrl + res.data })
                if (this.subject == '') {
                    this.subject = this.attachmentList[0].name.split('.')[0]
                }
                this.$toast.success('上传成功')
            })
            fileUploadUtils.trigger()
            // let res = await this.$refs.ChooseFile.open('', '', true)
            // if (res.flag) {
            //     let sourceData = res.data
            //     for (let index = 0; index < sourceData.length; index++) {
            //         const element = sourceData[index]
            //         let name = this.getName(element.name)
            //         this.attachmentList.push({ name: name, size: element.size, url: element.url })
            //     }
            //     this.$toast.success('上传成功')
            //     // this.reGetList()
            // }
        },
        uploadFile(e) {
            let _this = this
            let option = {
                onload(res) {
                    let url = _this.Global.config.downloadBaseUrl + res.data
                    let name = _this.getName(res.name)
                    _this.attachmentList.push({ name: name, size: res.size, url: url })
                    // setTimeout(() => {
                    //     var scrollDom = document.getElementById('scrollTopList')
                    //     scrollDom.scrollTop = scrollDom.scrollHeight
                    // }, 200)
                }
            }
            this.$refs.fileUpload.fileChange(e, option)
        },
        getName(name) {
            this.su++
            let arry = name.split('.')
            let names = this.month.toString() + this.time.toString() + '_' + this.su + '.' + arry[arry.length - 1]

            return names
        },
        SelectRestaurants(item) {
            let str = ''
            switch (this.restaurantsType) {
                case 'recipientsId':
                    str = 'recipientsPicking_value'
                    break
                case 'ccId':
                    str = 'ccPicking_value'
                    break
                case 'DarkId':
                    str = 'DrakPicking_value'
                    break
            }
            this.divShow = false
            this.$refs[str].SelectRestaurants(item)
        },
        getStyle(type, restaurants, associationShow) {
            this.restaurantsType = type
            this.restaurants = restaurants
            this.divShow = associationShow
            this.$nextTick(() => {
                var divHeigth = document.getElementById(type).offsetHeight
                var domTop = document.getElementById(type).offsetTop
                var domTop2 = document.getElementsByTagName('body')[0].clientHeight
                this.topS = domTop + divHeigth - 2
                this.heigthS = domTop2 - this.topS
            })
            if (this.beforeType != type) {
                this.beforeType = type
                switch (this.restaurantsType) {
                    case 'recipientsId':
                        this.$refs.ccPicking_value.autoReturn()
                        this.$refs.DrakPicking_value.autoReturn()
                        break
                    case 'ccId':
                        this.$refs.recipientsPicking_value.autoReturn()
                        this.$refs.DrakPicking_value.autoReturn()
                        break
                    case 'DarkId':
                        this.$refs.ccPicking_value.autoReturn()
                        this.$refs.DrakPicking_value.autoReturn()
                        break
                }
            }
        },
        getContenDivheigth() {
            this.$nextTick(() => {
                var domTop2 = document.getElementsByTagName('body')[0].clientHeight
                var domTop = document.getElementById('contenDivParentId').offsetTop // 显示到顶部的高度
                this.contenDivheigth = domTop2 - domTop - 60
            })
        },
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('发送')
            rightMenu.onClick(async () => {
                this.autoReturn()
                await this.$nextTick()
                setTimeout(() => {
                    this.leaveMode = 'seend'
                    this.VerificationShow('seend')
                }, 600)
            })
        },
        // 获取页面数据
        async getPersonalData() {
            let data = {
                type: 'list',
                pageN: 1,
                pageSize: 100
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.SystemSet.mailset.mailaccount.accounts
                let res = await this.axios.get(url, { params: data })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isArray(res.data.data)) {
                    let dataR = res.data.data
                    let list = []
                    // let listShow = []
                    for (let i = 0; i < dataR.length; i++) {
                        list.push({
                            name: dataR[i].sendDisplayName + '<' + dataR[i].mailAccount + '>',
                            value: dataR[i].sendDisplayName,
                            senduser: dataR[i].mailAccount,
                            replyAddress: dataR[i].replyAddress,
                            index: i,
                            buttonIndex: i
                        })
                        // listShow.push(dataR[i].mailAccount)
                    }
                    this.fromExArry = list
                    // this.fromExArryShow = listShow
                    this.fromEx = listShow.length > 0 ? listShow[0] : ''
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 发件人
        SelectfromEx(item) {
            this.fromEx = item.senduser
            this.fromExCheck = item.index
        },
        // 验证后显示
        async VerificationShow(type, next) {
            this.ParameterAcquisition() // 获取参数
            let message = ''
            message = await this.RequiredFieldValidator() // 必填验证
            if (message != '') {
                if (type == 'leave') {
                    if (message != '邮件内容不能为空') {
                        next()
                        return
                    }
                } else {
                    this.$toast.fail(message)
                    return
                }
            }
            if (next) {
                next(false)
            }

            this.sendingShow = true // 发送方式显示
        },
        // 可请求验证   //根据设置1    立即发送2   延迟3  定时4
        async ThroughVerification(type) {
            if (type == 1) {
                this.JudgingBySetting()
            } else {
                let tt = await this.recipientCheckPost()
                if (tt != 2) {
                    switch (type) {
                        case 2: // 立即发送2
                            this.$refs.SendAction.SendImmediately()
                            break
                        case 3://  延迟3
                            this.$refs.SendAction.delayOrTiming()
                            break
                        case 4: // 定时4
                            this.$refs.SendAction.delayOrTiming()
                            break
                    }
                }
            }
        },
        // 根据设置判短
        async JudgingBySetting() {
            let blg = true
            // 智能检测并提示添加附件（如主题或正文提到附件）
            if (this.SettingInformationData.IntelligentDetectionAndPromptAdd == '1' || this.SettingInformationData.PopUpMailCheckItem == '1') {
                blg = await this.PromptShow()
            }
            if (!blg) { return }
            let tt = await this.recipientCheckPost()
            if (tt == 2) {
                return
            } else {
                // this.showPrompt = tt
            }
            if (this.SettingInformationData.AutomaticallyDelay == '1') { // 发送
                this.$dialog.confirm({
                    title: '提示',
                    message: '邮件将在' + this.SettingInformationData.Timevalue + '分钟之后发送！'
                }).then(() => {
                    this.$refs.SendAction.OrdinarySend() // 根据设定时间发送
                }).catch(() => {
                    this.$toast.fail('已取消发送')
                })
            } else { // 立即发送
                this.$refs.SendAction.SendImmediately()
            }
        },
        // 参数获取
        async ParameterAcquisition() {
            let data = {}
            this.toArry = []
            this.toCCArry = []
            this.bccArry = []
            this.recipients = this.FormatData(this.$refs.recipientsPicking_value.getArry(), 'toArry')
            this.cc = this.FormatData(this.$refs.ccPicking_value.getArry(), 'toCCArry')
            this.bcc = this.FormatData(this.$refs.DrakPicking_value.getArry(), 'bccArry')
            let htmlContentStr = document.getElementById('htmlContentId').innerHTML
            let send = this.fromExArry.filter(item => item.senduser == this.fromEx)[0]
            let name = ''
            let senduser = ''
            let replyAddress = ''
            if (send) {
                name = send.value.split('@')[0]
                senduser = send.senduser
                replyAddress = send.replyAddress
            } else {
                this.fromEx = ''
            }
            data.sendMailInfo = {
                sendDisplayName: name,
                sendUser: senduser,
                recipients: this.recipients,
                cc: this.cc,
                bcc: this.bcc,
                htmlContent: htmlContentStr, // this.htmlContent, // 模板+编辑器
                subject: this.subject, // 主题
                originIp: '', // this.Global.IP,
                priority: 3, // _this.sender.grade == true ? 1 : 3, // 等级
                tags: [], // _this.selectTags,
                // 千里眼
                enableTrack: this.enableTrack,
                // 请求回执开关
                reqReceipt: false,
                // attachments: this.attachments,
                attachmentList: this.attachmentList,
                // attachmentSizes: this.attachmentSizes,
                // OriginalMailContent: this.OriginalMailContent, // 原邮件内容
                // OriginalMailContentback: this.OriginalMailContentback
                replyTo: [{ name: replyAddress, address: replyAddress }]
                // this.substringName(this.fromExArry[this.fromExCheck].replyAddress) + '<' + this.fromExArry[this.fromExCheck].replyAddress + '>'
            }
            if (this.originMid) {
                data.sendMailInfo.originMid = this.originMid
            }
            if (this.originMsgId) {
                data.originMsgId = this.originMsgId
            }
            this.ParameterData = data
        },
        // 参数回写
        ParameterBackWrite(data, type) {
            if (data.sendMailInfo.messageId) {
                this.originMsgId = data.sendMailInfo.messageId
            }
            this.SettingInformationData = data.SettingInformationData
            this.AutomaticallyDelay = this.SettingInformationData.AutomaticallyDelay
            // 写邮件添加模板
            if (data.sendMailInfo.attachmentList && data.sendMailInfo.attachmentList.length > 0) {
                data.sendMailInfo.attachmentList.forEach(element => {
                    if (element.url) {
                        element.size = element.size * 1024 // 单位转换问题
                        element.url = urlIsEncode(element.url)
                    }
                })
                this.attachmentList = data.sendMailInfo.attachmentList
            }
            this.OriginalMailContent = data.sendMailInfo.htmlContent == undefined ? '' : data.sendMailInfo.htmlContent
            this.templateHtmlContent = data.sendMailInfo.templateHtmlContent || '<div><br/></div><div><br/></div><div><br/></div>'
            if (type == 'W' || type == 'A') {
                this.ccShow = false
                this.DarkShow = false
                this.htmlContent = this.templateHtmlContent // 模板写入编辑器
            } else {
                this.htmlContent = this.templateHtmlContent + this.OriginalMailContent
                this.recipientsArry = data.sendMailInfo.recipients
                if (data.sendMailInfo.cc && data.sendMailInfo.cc.length > 0) {
                    this.ccShow = true
                    this.DarkShow = true
                    this.ccArry = data.sendMailInfo.cc
                } else {
                    this.ccShow = false
                    this.DarkShow = false
                }
                this.subject = data.sendMailInfo.subject
            }

            if (data.sendMailInfo.sendUser != undefined) {
                this.fromEx = data.sendMailInfo.sendUser
                this.getReplyAddress(data.sendMailInfo.sendDisplayName)
            }
            if (data.JurisdictionData != undefined) { //
                if (data.JurisdictionData.otLimitMailSize == 0) {
                    this.sizeAttachMx = data.JurisdictionData.otLimitMailSizeValue
                }
                this.prohibitIput = data.JurisdictionData.otProhibitInputAddr
                this.otBeforeLable = data.JurisdictionData.otBeforeLable
                // this.NotPutOnCustSend = data.JurisdictionData.otNotPutOnCustSend == 0 ? true : false;
            } else {
                this.sizeAttachMx = 0
                this.prohibitIput = false
                this.otBeforeLable = false
                // this.NotPutOnCustSend = false;
            }

            if (type == 'A') {
                let { subject, url, size } = this.$route.params
                if (url) {
                    this.attachmentList.push({ name: subject, url: urlIsEncode(url), size: size })
                    if (this.subject == '') {
                        this.subject = subject.split('.')[0]
                    }
                }
            }
            this.focusStaus = true
            this.getContenDivheigth()
        },
        FormatData(Picking, type) {
            let pingStr = []
            for (let i = 0; i < Picking.length; i++) {
                if (type == 'toArry') {
                    this.toArry.push(Picking[i].mailAddress)
                } else if (type == 'toCCArry') {
                    this.toCCArry.push(Picking[i].mailAddress)
                } else if (type == 'bccArry') {
                    this.bccArry.push(Picking[i].mailAddress)
                }
                if (Picking[i].custId != undefined) {
                    pingStr.push({ name: Picking[i].contName, address: Picking[i].mailAddress })
                } else {
                    pingStr.push({ name: this.substringName(Picking[i].mailAddress), address: Picking[i].mailAddress })
                }
            }
            return pingStr
        },
        // 名称截取2
        substringName(adderss) {
            let str = adderss.split('@')
            return str[0]
        },
        // 必填验证
        RequiredFieldValidator() {
            let blgstr = ''
            if (this.fromEx == '') {
                blgstr = '发件人不能为空'
                return blgstr
            }
            //  console.log(this.recipients.toString())
            if (this.recipients == '') {
                blgstr = '收件人不能为空'
                return blgstr
            }
            if (this.subject == '') {
                blgstr = '主题不能为空'
                return blgstr
            }
            let htmlContentStr = document.getElementById('htmlContentId').innerText.trim()
            if (htmlContentStr == '') {
                // let htmlContentStrHtml = document.getElementById('htmlContentId').innerHTML
                // if (htmlContentStrHtml.indexOf('iframeId') != -1) { // 有iframe
                //     let iframeConten = document.getElementById('iframeId').contentWindow.document.body.innerText
                //     if (iframeConten == '') {
                //         blgstr = '邮件内容不能为空2'
                //         return blgstr
                //     }
                // } else {
                blgstr = '邮件内容不能为空'
                return blgstr
                // }
            }

            // if (this.otBeforeLable) { // 发送前必须加标签
            //     if (this.selectTags.length <= 0) {
            //         blgstr = this.$t('mxpcweb.mail.1528977327415')//  "发送前必须打标签";
            //         return blgstr
            //     }
            // }
            // if (this.sizeAttach != 0 && this.sizeAttachMx != 0) { // 上传附件不为0并且限制大小不等于0
            //     let mx = this.sizeAttachMx * 1048576
            //     if (this.sizeAttach > mx) {
            //         blgstr = this.$t('mxpcweb.mail.1529737552495', { a: this.sizeAttachMx })// 附件不能超过20M
            //         return blgstr
            //     }
            // }
            return blgstr
        },
        // 发送前提示
        PromptShow() {
            return new Promise((resolve, reject) => {
                let msg = ''
                let showArry = []
                const h = this.$createElement
                let num = 1
                // 附件没有添加提示
                if (this.SettingInformationData.IntelligentDetectionAndPromptAdd == '1' && this.attachmentList.length == 0) {
                    // 附件
                    if (this.subject.indexOf('附件') != -1) { // 包含附件
                        msg = '1、主题'
                    }
                    if (this.htmlContent.indexOf('附件') != -1) { // 包含附件
                        msg = msg == '' ? '1、正文' : '1、主题/正文'
                    }
                }
                if (msg != '') {
                    msg = msg + '包含附件，目前没有添加附件。'
                    showArry.push(h('div', null, msg))
                    num++
                }
                // 发送前弹出提示
                if (this.SettingInformationData.PopUpMailCheckItem == '1') {
                    this.SettingInformationData.sendCheckList.forEach((element, index) => {
                        num = num + index
                        msg = msg + num + '、' + element.checkName
                        showArry.push(h('div', null, num + '、' + element.checkName))
                    })
                }
                if (showArry.length > 0) {
                    this.$dialog.confirm({
                        title: '提示',
                        message: msg
                    }).then(() => {
                        resolve(true)
                        // on confirm
                    }).catch(() => {
                        resolve(false)
                    })
                } else {
                    resolve(true)
                }
            })
        },
        // 邮件接收者检查接口
        async recipientCheckPost() {
            let returnFlag = 2
            let params = {
                'to': this.toArry,
                'cc': this.ccArryList,
                'bcc': this.bccArry
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.recipientCheckPost
                let res = await this.axios.post(url, params)

                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    if (this.Global.utils.isObject(res.data.data)) {
                        if ((res.data.data.to && res.data.data.to.length > 0) || (res.data.data.cc && res.data.data.cc.length > 0) || (res.data.data.bcc && res.data.data.bcc.length > 0)) {
                            returnFlag = 1
                        } else {
                            returnFlag = 0
                        }
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return returnFlag
        },
        pageInit() {
            if (this.$route.path == '/mail/NewMail') {
                this.Routine = false
                this.cleanCoten()
                let { actionType, templateid, originMid } = this.$route.params
                this.actionType = actionType == undefined ? 'W' : actionType
                this.templateid = templateid == undefined ? 0 : templateid
                this.originMid = originMid == undefined ? '' : originMid.toString()
                this.actionTypeGetData()
                this.getPersonalData()
                this.setMenu()
            }
        },
        actionTypeGetData() { // 根据页面类型加载值
            this.sourceMid = ''
            switch (this.actionType) {
                case 'W':// 写邮件
                    this.sendType = 'new'
                    this.MailWrite(this.actionType)
                    break
                case 'R':// 回复邮件
                    this.sendType = 'reply'
                    this.ReplyMail(this.originMid, 'R', 0)
                    break
                case 'Rl':// 回复全部
                    this.sendType = 'reply'
                    this.ReplyMail(this.originMid, 'Rl', 0)
                    break
                case 'F':// 转发
                    this.sendType = 'repost'
                    this.ReplyMail(this.originMid, 'F', 0)
                    break
                case 'D':// 草稿重发
                case 'Z':// 再次发送
                    this.sendType = 'new'
                    //   this.originMid.toString() // 草稿
                    this.ManuscriptBackWriting(this.originMid, this.actionType, 0)
                    break
                case 'A':// 作为附件发送
                    this.sendType = 'new'
                    this.MailWrite(this.actionType)
                    break
                case 'customerMail': // 客户写邮件
                    this.sendType = 'new'
                    this.MailWrite('W')
                    this.customerMailWrite()
                    break
                case 'FileMail': // 知识库写邮件
                    this.sendType = 'new'
                    this.MailWrite('W')
                    this.FileMailWrite()
                    break
            }
        },
        // 草稿重写
        ManuscriptBackWriting(mId, type, templateid) {
            // let _this = this
            if (type == 'D') {
                this.sourceMid = mId // 草稿mid
            } else {
                this.sourceMid = ''
            }
            let dataParams = {
                mId: mId,
                type: 'details'
            }
            var p1 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.Mail.getMailCurrent, { params: dataParams }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK && res.data) {
                        resolve(res.data)
                    }
                }, function (res) {
                    this.$message.error(this.msg(res.data))
                })
            })
            let Params = {}
            var p2 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.Mail.getSettingInformation, { params: Params }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK && res.data) {
                        resolve(res.data.data)
                    }
                }, function (res) {
                    this.$message.error(this.msg(res.data))
                })
            })
            let _this = this
            Promise.all([p1, p2]).then((results) => {
                let data = results[0].data
                if (this.Global.utils.isObject(data)) {
                    if (data.fromEx.length > 0) {
                        this.fromEx = data.fromEx[0].address
                        // let fromExPersonal = data.fromEx[0].personal == undefined ? _this.substringName(
                        //     data.fromEx[0].address) : data.fromEx[0].personal
                        // _this.sender.selectValue = fromExPersonal + '<' + data.fromEx[0].address +
                        //     '>'
                        // _this.selectValueback = fromExPersonal + '<' + data.fromEx[0].address +
                        //     '>'
                    }

                    for (let i = 0; i < data.recipients.length; i++) {
                        this.recipientsArry.push({
                            mailAddress: data.recipients[i].address,
                            custId: data.recipients[i].custId != undefined ? data.recipients[i].custId : undefined,
                            name: data.recipients[i].contName == undefined ? _this.substringName(
                                data.recipients[i].address) : data.recipients[i].contName
                        })
                    }
                    if (data.cc != undefined) {
                        this.ccShow = true
                        this.DarkShow = true
                        for (let i = 0; i < data.cc.length; i++) {
                            _this.ccArry.push({
                                mailAddress: data.cc[i].address,
                                custId: data.cc[i].custId != undefined ? data.cc[i].custId : undefined,
                                name: data.cc[i].contName == undefined ? _this.substringName(
                                    data.cc[i].address) : data.cc[i].contName
                            })
                        }
                    } else {
                        _this.ccShow = false
                    }
                    _this.subject = data.subject // 主题
                    _this.templateHtmlContent = data.htmlContent
                    if (data.inReplyTo) {
                        _this.originMsgId = data.inReplyTo
                    }
                    if (data.originMid) {
                        console.log('originMid' + data.originMid)
                        _this.sendType = 'reply'
                        _this.originMid = data.originMid.toString()
                    }
                    let htmlUE = _this.templateHtmlContent // 模板写入编辑器
                    htmlUE += '<div><br/></div><div><br/></div>'
                    // _this.selectTags = data.tags
                    // 千里眼
                    // _this.sender.isClairvoyant = data.enableTrack
                    // 请求回执开关
                    // _this.sender.isReceipt = data.reqReceipt
                    if (data.attachmentList != undefined) {
                        _this.attachmentList = data.attachmentList
                    }
                    this.htmlContent = htmlUE
                    this.getContenDivheigth()
                    // _this.myUE.setContent(htmlUE, false)
                    // this.myUE.focus()
                }
                _this.SettingInformationData = results[1]
            })
        },
        customerMailWrite() { // 客户写邮件
            let { recipientsArry } = this.$route.params
            this.recipientsArry = recipientsArry
        },
        async folders_moveFiles(type = 'new', fileArr) {
            this.$toast.loading({ message: '加载中...' })
            let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_moveFiles
            let data = { files: fileArr }
            let res = await this.axios.post(url, data)
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.$toast.clear()
                let fileArry = res.data.data
                if (this.Global.utils.isArray(fileArry)) {
                    for (let index = 0; index < fileArry.length; index++) {
                        const fileobj = {}
                        const element = fileArry[index]
                        fileobj.url = this.Global.config.downloadBaseUrl + element.attachmnentId
                        // fileobj.status = 1
                        fileobj.name = element.fileName
                        fileobj.size = element.fileSize// (element.fileSize / 1024).toFixed(2)
                        this.attachmentList.push(fileobj)
                    }
                }
                if (type == 'new') {
                    this.MailWrite('W')
                }
            } else {
                this.$toast.clear()
                this.$toast.fail(res.data.msg)
            }
        },
        FileMailWrite() { // 知识库写邮件
            let { fileId, fileVersion } = this.$route.params
            let fileArr = [{ fileId: fileId, fileVersion: fileVersion }]
            this.folders_moveFiles('new', fileArr)
        },
        // 写邮件写入模板
        async MailWrite(type) {
            console.log('写邮件写入模板')
            let params = {
                type: this.type,
                templateId: this.templateid
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.MailWrite
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let data = res.data.data
                    // && !_.isEmpty(data.sendMailInfo)
                    if (this.Global.utils.isObject(data)) {
                        this.ParameterBackWrite(data, type)
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 回复邮件
        async ReplyMail(mId, type, templateid) {
            let params = {
                mId: mId,
                type: type,
                templateId: templateid
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
                params.targetCtid = this.currentCtid
            } else {
                delete params.ctid
                params.targetCtid = this.companie.ctId
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.MailEditor
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    this.ParameterBackWrite(res.data.data, type)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        getReplyAddress(mailAccount) {
            for (let i = 0; i < this.fromExArry.length; i++) {
                if (this.fromExArry[i].senduser == mailAccount) {
                    this.fromExCheck = i
                }
            }
        },
        pageShowChange(blg) {
            this.pageShow = blg
            if (blg) {
                this.setMenu()
            } else {
                this.Global.utils.rightMenu.clearMenu()
            }
        },
        sendingShowClose() {
            this.sendingShow = false
        },
        SaveFailed(taskId) {
            this.sourceMid = taskId
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()

        if (flag || flag2 || flag3 || this.isShowSelectDoc) {
            if (this.isShowSelectDoc) {
                this.closeSelectDocFile()
            }
            next(false)
        } else if (this.isShowSelectContact != '') {
            next()
        } else {
            if (this.Routine) {
                next()
            } else {
                this.leaveMode = 'leave'
                this.VerificationShow('leave', next)
                // next(this.Routine)
            }
        }
    },
    watch: {
        $route(to, form) {
            if (to.name == 'NewMail') {
                if (form.name != 'selectCustomer' && form.name != 'selectContacts') {
                    this.pageInit()
                }
                if (form.name == 'selectCustomer') {
                    this.setMenu()
                }
                if (form.name == 'selectContacts') { // 选择联系人
                    this.setMenu()
                    let ContactsArray = JSON.parse(this.Global.utils.getItem('ContactsArray'))
                    if (this.isShowSelectContact && ContactsArray && ContactsArray.length > 0) {
                        ContactsArray.forEach(element => {
                            if (this.isShowSelectContact == 'recipientsPicking_value') {
                                this.recipientsArry = this.$refs.recipientsPicking_value.getArry()
                                this.recipientsArry.push(element)
                            } else if (this.isShowSelectContact == 'ccPicking_value') {
                                this.ccArry = this.$refs.ccPicking_value.getArry()
                                this.ccArry.push(element)
                            } else if (this.isShowSelectContact == 'DrakPicking_value') {
                                this.DarkArry = this.$refs.DrakPicking_value.getArry()
                                this.DarkArry.push(element)
                            }
                        })
                    }
                    this.Global.utils.removeItem('ContactsArray')
                    this.isShowSelectContact = ''
                }
                if (this.$route.query.hasOwnProperty('url')) this.pushAttachment(this.$route.query)
            }
        }
    },
    components: {
        'input-auto': InputAuto,
        'send-action': SendAction,
        'upload-file': UploadFile,
        'file-list-show': fileListShow,
        'choose-file': ChooseFile,
        'select-doc': SelectDoc
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
